<script setup>
import { format, fromUnixTime } from 'date-fns';
import { computed } from 'vue';
import { MIcon, MText, useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import LikeButton from '@/components/common/LikeButton';
import OwnerAvatar from '@/components/common/OwnerAvatar';
import SuccessBar from '@/components/common/SuccessBar';
import ThumbnailCard from '@/components/common/ThumbnailCard';
import useDevice from '@/composables/common/device';
import useGaEvent from '@/composables/common/gaEvent';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const props = defineProps({
  hero: { type: Object, required: true },
  index: { type: Number, required: true },
});

const { pc } = useDevice();
const prefix = computed(() => (pc.value ? '' : 'sp_'));
const parameterizedUrl = computed(
  () => `${props.hero.url}?from=${prefix.value}hero_image_${props.index + 1}`,
);

const { sendClickEvent, sendSelectContentEvent } = useGaEvent();
const { xs } = useDisplay();

const onClick = () => {
  sendClickEvent('project_hero', props.index);
  sendSelectContentEvent(props.hero.project.id, SELECT_CONTENT_TYPES.PROJECT);
};
</script>

<template>
  <a
    class="hero-item flex flex-col h-full"
    :href="parameterizedUrl"
    :target="hero.target"
    tabindex="-1"
    @click="onClick"
  >
    <article class="contents">
      <ThumbnailCard
        aspect-ratio="16 / 9"
        class="mb-4 sm:mb-6"
        eager
        height-sm="288"
        height="180"
        :src="hero.thumbnail_image_url"
        width-sm="512"
        width="320"
      />
      <div class="grow flex gap-2 sm:gap-3.5">
        <OwnerAvatar
          v-if="hero.project?.user?.image_url"
          :alt="hero.project.user.name"
          :is-selected-owner="hero.project.user.is_selected_user"
          size="32"
          sm="48"
          :src="hero.project.user.image_url"
        />
        <div class="grow flex flex-col gap-3 sm:gap-4">
          <MText
            :class="xs ? 'min-h-[2.125rem]' : 'min-h-[3.125rem]'"
            max-lines="2"
            size="x-small"
            sm="large"
            tag="h3"
          >
            {{ hero.title }}
          </MText>
          <div class="flex items-center justify-between mt-0.5">
            <MText sm="x-large" tag="p" weight="bold">
              {{
                hero.project?.collected_money?.toLocaleString('ja-JP', {
                  currency: 'JPY',
                  style: 'currency',
                })
              }}
            </MText>
            <LikeButton
              :index
              :size="xs ? 18 : 'medium'"
              :project-id="hero.project?.id"
              tracking-id="hero"
            />
          </div>
          <div class="flex items-center gap-2 sm:gap-4">
            <MIcon
              class="-mr-1.5 sm:-mr-3"
              size="12"
              sm="18"
              color="secondary"
              name="time"
            />
            <MText
              v-if="hero.project?.time_left_label"
              size="2x-small"
              sm="medium"
              tag="time"
              :datetime="
                hero.project?.expiration_date
                  ? format(
                      fromUnixTime(hero.project?.expiration_date || 0),
                      'yyyy-MM-dd',
                    )
                  : ''
              "
            >
              {{ hero.project?.time_left_label }}</MText
            >
            <SuccessBar :height="xs ? 4 : 6" :percent="hero.project?.percent" />
            <MText size="2x-small" sm="medium" tag="span"
              >{{ hero.project?.percent }}%</MText
            >
          </div>
        </div>
      </div>
    </article>
  </a>
</template>

<style lang="scss" scoped>
.hero-item:hover {
  text-decoration: none;

  &:not(:has(.like-button:hover)) .thumbnail-card {
    opacity: 0.72;
  }
}

.thumbnail-card {
  transition: opacity 0.2s;
}

.min-h-\[2\.125rem\] {
  min-height: 2.125rem; /* 34px */
}

.min-h-\[3\.125rem\] {
  min-height: 3.125rem; /* 50px */
}
</style>
