<script setup>
import RankLabel from '@/components/common/RankLabel';
import ProjectFilter from '@/components/discover/ProjectFilter';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import { FILTER_OPTIONS, NO_SORT_OPTION } from '@/consts/discover';

const { fetchMostFundedProjects, filter, loading, pagination, projects, sort } =
  useFetchProjects();

const filterOptions = FILTER_OPTIONS.map(option => ({
  ...option,
  value: false,
}));
</script>

<template>
  <ProjectFilter
    v-model:filter="filter"
    v-model:sort="sort"
    :filter-options
    :loading
    :sort-options="NO_SORT_OPTION"
    @fetch="fetchMostFundedProjects"
  />
  <ViewBase
    v-model:pagination="pagination"
    class="all-time-ranking-view"
    :loading
    :projects
    @fetch="fetchMostFundedProjects({ page: pagination.page })"
  >
    <template #[`project.thumbnail.append`]="{ project }">
      <RankLabel v-if="project.rank <= 100" :rank="project.rank" />
    </template>
  </ViewBase>
</template>
