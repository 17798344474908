<script setup>
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import { SORT_OPTION_KEY_POPULAR } from '@/consts/discover';
import { PROJECT_DISPLAY_NUMBER } from '@/consts/home';

// TODO: PX-2704 ABテスト終了後に削除
const props = defineProps({
  isPopular: { type: Boolean, default: false },
});

const { fetchComingSoonProjects, loading, projects, sort } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(() => {
  // TODO: PX-2704 ABテスト終了後に不要箇所削除
  let perPage = PROJECT_DISPLAY_NUMBER * 5;
  if (props.isPopular) {
    sort.value = SORT_OPTION_KEY_POPULAR;
    perPage = PROJECT_DISPLAY_NUMBER;
  }
  fetchComingSoonProjects({
    perPage,
    random: PROJECT_DISPLAY_NUMBER,
  });
});
</script>

<template>
  <ProjectSectionBase
    coming-soon
    href="/discover/coming-soon"
    label="coming_soon"
    :loading
    :projects
    title="もうすぐ開始"
  />
</template>
