<script setup>
import ProjectFilter from '@/components/discover/ProjectFilter';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const {
  fetchProjectsByLocationIds,
  filter,
  loading,
  pagination,
  projects,
  sort,
} = useFetchProjects();
</script>

<template>
  <ProjectFilter
    v-model:filter="filter"
    v-model:sort="sort"
    :loading
    @fetch="
      fetchProjectsByLocationIds(
        $route.params.locationIds.split(',').map(Number),
      )
    "
  />
  <ViewBase
    v-model:pagination="pagination"
    class="location-view"
    :loading
    :projects
    @fetch="
      fetchProjectsByLocationIds(
        $route.params.locationIds.split(',').map(Number),
        {
          page: pagination.page,
        },
      )
    "
  />
</template>
