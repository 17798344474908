<script setup>
import { ref, useTemplateRef } from 'vue';
import { MSelect } from '@ca-crowdfunding/makuake-ui-n';
import RankLabel from '@/components/common/RankLabel';
import ThumbnailCard from '@/components/common/ThumbnailCard';
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import RANKING_CATEGORIES from '@/consts/ranking';

const section = useTemplateRef('section');
const selectedValue = ref(RANKING_CATEGORIES[0].value);

const { fetchRankedProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

const fetchProjects = async () => {
  await fetchRankedProjects({ tagGroup: selectedValue.value });
  section.value?.container?.$el?.scrollTo(0, 0);
};

setupIntersection(fetchRankedProjects);
</script>

<template>
  <ProjectSectionBase
    ref="section"
    container-tag="ol"
    href="/discover/ranking"
    label="ranking"
    :loading
    :projects
    show
    title="今日のランキング"
  >
    <template #[`title.append`]>
      <MSelect
        v-model="selectedValue"
        aria-label="グループの選択"
        class="font-bold"
        density-sm="compact"
        :options="RANKING_CATEGORIES"
        size="small"
        sm="medium"
        variant="tonal"
        @change="fetchProjects"
      />
    </template>
    <template #[`project.thumbnail`]="{ project }">
      <ThumbnailCard
        v-if="project.image_url"
        aspect-ratio="16 / 9"
        class="mb-4"
        height="252"
        height-sm="288"
        :src="project.image_url"
        width="448"
        width-sm="512"
      >
        <template #text>
          <RankLabel v-if="project?.rank" :rank="project.rank" />
        </template>
      </ThumbnailCard>
    </template>
  </ProjectSectionBase>
</template>

<style lang="scss" scoped>
$gap: 1.5rem;

.ranking-section :deep(li) {
  max-width: 32rem;
  min-width: 18rem;
  width: calc(75% - 0.375rem);

  @for $i from 2 through 6 {
    @container (min-width: #{320 * $i}px) {
      width: calc(150% / $i - $gap * ($i - 1) / $i);
    }
  }
}
</style>
