<script setup>
import { computed } from 'vue';
import ThumbnailCard from '@/components/common/ThumbnailCard';
import useLink from '@/composables/link';
import useGaEvent from '@/composables/common/gaEvent';

const props = defineProps({
  contentId: {
    type: [String, Number],
  },
  contentType: {
    type: String,
  },
  href: {
    type: String,
    required: true,
  },
  index: {
    type: Number,
  },
  label: {
    type: String,
  },
});

const { sendClickEvent, sendSelectContentEvent } = useGaEvent();
const { resolveLink } = useLink();

const target = computed(() => resolveLink(props.href).target);

const onClick = () => {
  sendClickEvent(props.label, props.index);
  sendSelectContentEvent(props.contentId, props.contentType);
};
</script>

<template>
  <li class="article-item mb-4 sm:mb-6 last:mb-0">
    <a class="flex items-center gap-4" :href :target @click="onClick">
      <article class="article-item contents">
        <ThumbnailCard aspect-ratio="1 / 1" class="shrink-0">
          <template #image>
            <slot name="image" />
          </template>
        </ThumbnailCard>
        <div class="overflow-hidden">
          <slot />
        </div>
      </article>
    </a>
  </li>
</template>

<style scoped>
.thumbnail-card {
  height: 80px;
  max-height: 25vw;
  max-width: 25vw;
  width: 80px;
}

.thumbnail-card :deep(img) {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

@media (min-width: 640px) {
  .thumbnail-card {
    height: 160px;
    width: 160px;
  }
}
</style>
