<script setup>
import ProjectFilter from '@/components/discover/ProjectFilter';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const { fetchAllProjects, filter, loading, pagination, projects, sort } =
  useFetchProjects();
</script>

<template>
  <ProjectFilter
    v-model:filter="filter"
    v-model:sort="sort"
    :loading
    @fetch="fetchAllProjects"
  />
  <ViewBase
    v-model:pagination="pagination"
    class="all-view"
    :loading
    :projects
    @fetch="fetchAllProjects({ page: pagination.page })"
  />
</template>
