<script setup>
import ProjectFilter from '@/components/discover/ProjectFilter';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

defineProps({
  tagId: {
    type: [String, Number],
    required: true,
  },
});

const { fetchProjectsByTagIds, filter, loading, pagination, projects, sort } =
  useFetchProjects();
</script>

<template>
  <ProjectFilter
    v-model:filter="filter"
    v-model:sort="sort"
    :loading
    @fetch="fetchProjectsByTagIds($route.params.tagId)"
  />
  <ViewBase
    v-model:pagination="pagination"
    class="tag-view"
    :loading
    :projects
    @fetch="fetchProjectsByTagIds(tagId, { page: pagination.page })"
  />
</template>
