<script setup>
import { computed } from 'vue';
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import loggedInStatus from '@/modules/isLoggedinStatus';

/** @typedef {{ id: number; name: string; }} Tag */

const props = defineProps({
  recommendedTags: {
    type: /** @type {import('vue').PropType<Tag[]>} */ (Array),
    required: false,
    default: () => [],
  },
});

const recommendedTagIds = computed(() =>
  props.recommendedTags.map(tag => tag.id),
);

const userLoggedIn = loggedInStatus.isUserLoggedin();
const { fetchRemindedProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(() =>
  fetchRemindedProjects({ recommendedTagIds: recommendedTagIds.value }),
);
</script>

<template>
  <ProjectSectionBase
    v-if="userLoggedIn"
    label="project-end"
    :loading
    :projects
    title="応援購入忘れはありませんか"
  />
</template>
