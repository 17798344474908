<script setup>
import { ref } from 'vue';
import { MCol, MRow, MText } from '@ca-crowdfunding/makuake-ui-n';
import ThumbnailCard from '@/components/common/ThumbnailCard';
import SectionHeader from '@/components/home/SectionHeader';
import useLink from '@/composables/link';
import useRandomPicker from '@/composables/randomPicker';
import useGaEvent from '@/composables/common/gaEvent';
import useIntersection from '@/composables/common/intersection';
import api from '@/modules/api/v2/contents';
import { EVENT_DISPLAY_NUMBER } from '@/consts/home';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const partners = ref([]);
const { sendClickEvent, sendSelectContentEvent } = useGaEvent();
const { resolveLink } = useLink();
const { randomPick } = useRandomPicker();
const { setupIntersection } = useIntersection();

const fetchPartners = async () => {
  const { data } = await api.fetchAll({ per_page: EVENT_DISPLAY_NUMBER * 3 });
  if (data?.contents?.length)
    partners.value = randomPick(data.contents, EVENT_DISPLAY_NUMBER);
};

setupIntersection(fetchPartners);

const onClick = index => {
  sendClickEvent('event', index);
  sendSelectContentEvent(
    `event_${partners.value[index].id}`,
    SELECT_CONTENT_TYPES.ARTICLE,
  );
};
</script>

<template>
  <aside class="event-section">
    <SectionHeader title="取り組み・イベント" />
    <div class="max-w-4xl mx-auto">
      <MRow gap-sm="6" tag="ul">
        <MCol v-for="(partner, key) in partners" :key cols="6" md="4" tag="li">
          <a
            class="block pb-2 sm:pb-0"
            :href="partner.url"
            :target="resolveLink(partner.url).target"
            @click="onClick(key)"
          >
            <article>
              <ThumbnailCard
                alt=""
                aspect-ratio="16 / 9"
                class="mb-4"
                height-md="159"
                height-sm="246"
                height="162"
                :src="partner.image_url"
                width-md="283"
                width-sm="436"
                width="288"
              />
              <MText
                max-lines="3"
                size="x-small"
                sm="small"
                tag="h3"
                weight="bold"
              >
                {{ partner.title }}
              </MText>
            </article>
          </a>
        </MCol>
      </MRow>
    </div>
  </aside>
</template>
