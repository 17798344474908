<script setup>
import ProjectFilter from '@/components/discover/ProjectFilter';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const {
  fetchProjectsByCategorySlug,
  filter,
  loading,
  pagination,
  projects,
  sort,
} = useFetchProjects();
</script>

<template>
  <ProjectFilter
    v-model:filter="filter"
    v-model:sort="sort"
    :loading
    @fetch="fetchProjectsByCategorySlug($route.params.categorySlug)"
  />
  <ViewBase
    v-model:pagination="pagination"
    class="category-view"
    :loading
    :projects
    @fetch="
      fetchProjectsByCategorySlug($route.params.categorySlug, {
        page: pagination.page,
      })
    "
  />
</template>
