<script setup>
import { ref, useTemplateRef } from 'vue';
import { MSelect, useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import ProjectSectionBase from '@/components/home/ProjectSectionBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import RANKING_CATEGORIES from '@/consts/ranking';

const section = useTemplateRef('section');
const selectedValue = ref(RANKING_CATEGORIES[0].value);

const { xs } = useDisplay();
const { fetchRankedProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

const fetchProjects = async () => {
  await fetchRankedProjects({ tagGroup: selectedValue.value });
  section.value?.container?.$el?.scrollTo(0, 0);
};

setupIntersection(fetchRankedProjects);
</script>

<template>
  <ProjectSectionBase
    ref="section"
    container-tag="ol"
    href="/discover/ranking"
    :class="{ sm: !xs }"
    label="ranking"
    :loading
    :projects
    show
    title="今日のランキング"
  >
    <template #[`title.append`]>
      <MSelect
        v-model="selectedValue"
        aria-label="グループの選択"
        class="font-bold"
        density-sm="compact"
        :options="RANKING_CATEGORIES"
        size="small"
        sm="medium"
        variant="tonal"
        @change="fetchProjects"
      />
    </template>
    <template #[`loader.prepend`]="{ index }">
      <span
        v-if="index + 1"
        :class="`rank rank-${index + 1} text-secondary font-bold mr-2`"
        >{{ index + 1 }}</span
      >
    </template>
    <template #[`project.prepend`]="{ project }">
      <span
        v-if="project.rank"
        :class="`rank rank-${project.rank} text-secondary font-bold mr-2`"
        >{{ project.rank }}</span
      >
    </template>
  </ProjectSectionBase>
</template>

<style scoped>
.ranking-section :deep(li) {
  align-items: baseline;
  max-width: none;
  min-width: 0;
  width: auto;
}

.ranking-section :deep(li > :is(a, div)) {
  min-width: 14rem;
  width: calc(50vw - 0.375rem);
}

.ranking-section.sm :deep(li > :is(a, div)) {
  width: 25rem;
}

.rank {
  font-size: 4.0625rem;
  line-height: 1;
}

.sm .rank {
  font-size: 5.8125rem;
}

.rank-1 {
  color: #eacc30;
}

.rank-2 {
  color: #a2b0b9;
}

.rank-3 {
  color: #e6906c;
}
</style>
