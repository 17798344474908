<script setup>
import ProjectFilter from '@/components/discover/ProjectFilter';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import { NO_FILTER_OPTION } from '@/consts/discover';

const { fetchComingSoonProjects, filter, loading, pagination, projects, sort } =
  useFetchProjects();
</script>

<template>
  <ProjectFilter
    v-model:filter="filter"
    v-model:sort="sort"
    class="hidden"
    :filter-options="NO_FILTER_OPTION"
    :loading
    @fetch="fetchComingSoonProjects"
  />
  <ViewBase
    v-model:pagination="pagination"
    coming-soon
    class="coming-soon-view"
    :loading
    :projects
    @fetch="fetchComingSoonProjects({ page: pagination.page })"
  />
</template>
