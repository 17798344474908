<script setup>
import { useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import ProjectListBase from '@/components/home/ProjectListBase';
import useFetchProjects from '@/composables/common/fetchProjects';
import useIntersection from '@/composables/common/intersection';
import {
  RECOMMEND_ROWS,
  RECOMMEND_ROWS_MD,
  RECOMMEND_DISPLAY_NUMBER,
} from '@/consts/home';
import { SECTION_TITLE } from '@/consts/recommend';
import { computed } from 'vue';

const { smAndDown } = useDisplay();
const { fetchRecommendedProjects, loading, projects, userId } =
  useFetchProjects();
const { setupIntersection } = useIntersection();

const rows = computed(() =>
  smAndDown.value ? RECOMMEND_ROWS : RECOMMEND_ROWS_MD,
);

setupIntersection(() =>
  fetchRecommendedProjects({ perPage: RECOMMEND_DISPLAY_NUMBER }),
);
</script>

<template>
  <ProjectListBase
    class="recommended-section"
    label="recommend"
    :loading
    :projects
    :rows
    :title="SECTION_TITLE"
  >
    <input id="recommend_user_id" type="hidden" :value="userId" />
  </ProjectListBase>
</template>
