<script setup>
import { useTemplateRef } from 'vue';
import ProjectItem from '@/components/common/ProjectItem';
import ScrollContainer from '@/components/common/ScrollContainer';
import SectionHeader from '@/components/home/SectionHeader';
import { PROJECT_DISPLAY_NUMBER } from '@/consts/home';

defineProps({
  comingSoon: { type: Boolean, default: false },
  containerTag: { type: String, default: 'ul' },
  href: { type: String },
  projects: { type: Array },
  label: { type: String },
  loading: { type: Boolean, default: false },
  show: { type: Boolean, default: false },
  title: { type: String },
});

const container = useTemplateRef('container');
defineExpose({ container });
</script>

<template>
  <section
    v-if="show || loading || projects.length"
    :class="`${label}-section`"
  >
    <SectionHeader :href :label :scroll-container="container" :title>
      <slot v-if="$slots[`title.append`]" name="title.append" />
    </SectionHeader>
    <ScrollContainer
      ref="container"
      class="gap-3 sm:gap-6 -mb-6 pb-6"
      :tag="containerTag"
      padding-x="3"
    >
      <template v-if="loading">
        <li
          v-for="key in PROJECT_DISPLAY_NUMBER"
          :key
          class="flex flex-none min-w-56 max-w-96"
        >
          <slot name="loader.prepend" :index="key - 1" />
          <ProjectItem class="grow" :coming-soon loading>
            <template #[`loader.subtext`]>
              <slot name="project.loader.subtext" />
            </template>
            <template #[`loader.status`]>
              <slot name="project.loader.status" />
            </template>
          </ProjectItem>
        </li>
      </template>
      <li
        v-for="(project, index) in projects"
        v-else
        :key="project.id"
        class="flex flex-none min-w-56 max-w-96"
      >
        <slot name="project.prepend" :project />
        <ProjectItem
          class="grow"
          :coming-soon="project.is_coming_soon"
          :index
          :project
          :tracking-id="label"
        >
          <template #thumbnail>
            <slot name="project.thumbnail" :project />
          </template>
          <template #[`thumbnail.append`]>
            <slot name="project.thumbnail.append" :project />
          </template>
          <template #subtext>
            <slot name="project.subtext" :project />
          </template>
          <template #status>
            <slot name="project.status" :project />
          </template>
        </ProjectItem>
      </li>
    </ScrollContainer>
    <slot />
  </section>
</template>

<style lang="scss" scoped>
$gap: 1.5rem;

.scroll-container {
  container-type: inline-size;
}

.scroll-container > * {
  width: calc(50% - 0.375rem);

  @for $i from 2 through 6 {
    @container (min-width: #{320 * $i}px) {
      width: calc(100% / $i - $gap * ($i - 1) / $i);
    }
  }
}
</style>
