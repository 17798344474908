<script setup>
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  MButton,
  MCheckbox,
  MDrawer,
  MDrawerContent,
  MDrawerClose,
  MDrawerTitle,
  MDrawerTrigger,
  MPopover,
  MPopoverContent,
  MPopoverClose,
  MPopoverTrigger,
  MSelect,
  MText,
  useDisplay,
} from '@ca-crowdfunding/makuake-ui-n';
import useGaEvent from '@/composables/common/gaEvent';
import { FILTER_OPTIONS, SORT_OPTIONS } from '@/consts/discover';

const props = defineProps({
  /**
   * @typedef {Object} FilterOption
   * @property {string} key
   * @property {string} name
   * @property {boolean} value
   */
  /**
   * `NO_FILTER_OPTION`を渡すと絞り込みボタンが非表示になる
   * @type {ReadonlyArray<FilterOption>}
   */
  filterOptions: { type: Array, default: FILTER_OPTIONS },
  loading: { type: Boolean, default: false },
  /**
   * @typedef {Object} SortOption
   * @property {string} key
   * @property {string} label
   */
  /**
   * `NO_SORT_OPTION`を渡すとソートボタンが非表示になる
   * @type {ReadonlyArray<SortOption>}
   */
  sortOptions: { type: Array, default: SORT_OPTIONS },
});

const emit = defineEmits(['fetch']);

const filter = defineModel('filter', { type: Array, required: true });
const sort = defineModel('sort', { type: String, required: true });

const { smAndDown } = useDisplay();
const route = useRoute();
const router = useRouter();
const { sendClickEvent } = useGaEvent();

const options = computed(() =>
  props.sortOptions.map(option => ({
    label: option.label,
    value: option.key,
  })),
);

const toBoolean = str => str && str.toLowerCase() === 'true';

/**
 * 絞り込みとソートの状態をクエリストリングに合わせて更新
 * @param {Object} query
 */
const updateFilter = query => {
  filter.value =
    props.filterOptions
      ?.filter(option =>
        query[option.key] ? toBoolean(query[option.key]) : option.value,
      )
      ?.map(option => option.key) || [];

  sort.value = query.sort
    ? query.sort.toLowerCase()
    : props.sortOptions?.[0]?.key;
};

const deepEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

watch(
  () => ({ params: route.params, query: route.query }),
  (newValue, oldValue) => {
    if (deepEqual(newValue, oldValue)) return;
    updateFilter(newValue.query);
    emit('fetch');
  },
  { deep: true },
);

/**  URLのクエリストリングを更新 */
const updateQueryStr = () => {
  const query = {
    ...route.query,
    ...Object.fromEntries(
      props.filterOptions.map(option => [
        option.key,
        filter.value.includes(option.key),
      ]),
    ),
  };
  if (sort.value) query.sort = sort.value.toLowerCase();
  router.replace({ query });
};

/** アクション実行時の最低待機時間中か */
const waiting = ref(false);

/** ローディング開始と並行して最低待機時間のタイマーを走らせる */
watch(
  () => props.loading,
  newValue => {
    if (newValue) {
      waiting.value = true;
      setTimeout(() => {
        waiting.value = false;
      }, 300);
    }
  },
);

onBeforeMount(() => {
  updateFilter(route.query);
});
</script>

<template>
  <div class="project-filter flex justify-between gap-4 mb-4 sm:mb-6">
    <template v-if="filterOptions.length">
      <MDrawer v-if="smAndDown">
        <MDrawerTrigger as-child>
          <MButton
            class="shrink-0"
            prepend-icon="settingSlider"
            size="small"
            variant="outlined"
            @click.capture="sendClickEvent('filter_open')"
            @click="updateFilter(route.query)"
          >
            絞り込み
          </MButton>
        </MDrawerTrigger>
        <MDrawerContent>
          <div class="flex flex-col gap-6">
            <MDrawerTitle>絞り込み</MDrawerTitle>
            <MCheckbox
              v-for="option in filterOptions"
              :key="option.key"
              v-model="filter"
              :readonly="loading || waiting"
              :value="option.key"
            >
              <MText class="block" tag="span">{{ option.name }}</MText>
            </MCheckbox>
            <MDrawerClose as-child>
              <MButton
                block
                rounded
                @click.capture="sendClickEvent('filter_apply')"
                @click="updateQueryStr"
                >決定</MButton
              >
            </MDrawerClose>
          </div>
        </MDrawerContent>
      </MDrawer>
      <MPopover v-else>
        <MPopoverTrigger as-child>
          <MButton
            class="shrink-0"
            prepend-icon="settingSlider"
            size="small"
            variant="outlined"
            @click.capture="sendClickEvent('filter_open')"
            @click="updateFilter(route.query)"
          >
            絞り込み
          </MButton>
        </MPopoverTrigger>
        <MPopoverContent class="w-56 p-6 flex flex-col gap-6">
          <MCheckbox
            v-for="option in filterOptions"
            :key="option.key"
            v-model="filter"
            :readonly="loading || waiting"
            :value="option.key"
          >
            <MText class="block" tag="span">{{ option.name }}</MText>
          </MCheckbox>
          <MPopoverClose as-child>
            <MButton
              block
              rounded
              @click.capture="sendClickEvent('filter_apply')"
              @click="updateQueryStr"
              >決定</MButton
            >
          </MPopoverClose>
        </MPopoverContent>
      </MPopover>
    </template>
    <MSelect
      v-if="sortOptions.length"
      v-model="sort"
      :options
      @change="updateQueryStr"
    />
  </div>
</template>

<style scoped>
.m-select {
  margin-left: auto;
  overflow: hidden;
}
</style>
